<template>
  <div class="register-page">
    <div class="register-container">
      <h2>手机号注册</h2>
      <form @submit.prevent="handleRegister">
        <span class="error-message" v-if="registerError">{{ registerError }}</span>
        <div class="input-group">
          <input type="tel" v-model="phone" placeholder="请输入手机号" required @blur="validatePhone">
          <span v-if="phoneError" class="error-message">{{ phoneError }}</span>
        </div>
        <div class="input-group verification-code">
          <input type="text" v-model="verificationCode" placeholder="请输入验证码" required>
          <img :src="captchaImageData" alt="验证码" @click="refreshCaptcha" class="captcha-image">
        </div>
        <div class="input-group">
          <input type="password" v-model="password" placeholder="请输入密码" required>
        </div>
        <div class="agreement">
          <label>
            <input type="checkbox" v-model="agreeTerms" required>
            注册即为阅读及同意
          </label>
          <a href="#" class="terms-link">《用户服务协议》</a>
          和
          <a href="#" class="terms-link">《隐私协议》</a>
        </div>
        <button type="submit" class="register-btn" :disabled="!agreeTerms">注册账号</button>
      </form>
      <div class="login-link">
        已有账号>> <router-link to="/login">登录</router-link>
      </div>
    </div>
  </div>
</template>

<script>
import { validatePhoneNumber } from '@/utils/validators';
import apiService from '@/api/api';
export default {
  name: 'Register',
  data() {
    return {
      phone: '',
      verificationCode: '',
      password: '',
      agreeTerms: false,
      cooldown: 0,
      captchaImageData: 'http://sxwy-api.duoladuo.top/app-api/api/auth/verification',
      phoneError: '',
      registerError: ''
    }
  },
  methods: {
    handleRegister() {

      // 实现注册逻辑
      console.log('注册信息:', this.phone, this.verificationCode, this.password);
      // 调用API进行注册
      apiService.register(this.phone, this.verificationCode, this.password).then(response => {
        console.log(response);
        if(response.data.code === 0){
          this.$router.push('/login');
        }else{
          this.registerError = response.data.msg;
        }
      }).catch(error => {


        console.error('注册失败:', error);

      });
    },
    refreshCaptcha() {
      // 获取当前时间戳
      const timestamp = new Date().getTime();
      // 将时间戳添加到验证码图片URL中,以确保每次刷新都获取新的验证码
      this.captchaImageData = `http://sxwy-api.duoladuo.top/app-api/api/auth/verification?t=${timestamp}`;
    },
    validatePhone() {
      this.phoneError = validatePhoneNumber(this.phone);
    }
  }

}

</script>

<style scoped>
.captcha-image{
    height: 30px;
}
.error-message {
    color: #ff4d4f; /* 警告色 */
    font-size: 14px;
    margin-top: 5px;
    display: block;
}
.register-page {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #f5f5f5;
}

.register-container {
  background-color: white;
  padding: 30px;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  width: 300px;
}

h2 {
  text-align: center;
  color: #333;
  margin-bottom: 20px;
}

.input-group {
  margin-bottom: 15px;
}

input[type="tel"],
input[type="text"],
input[type="password"] {
  width: 100%;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 3px;
}

.verification-code {
  display: flex;
}

.verification-code input {
  flex-grow: 1;
  margin-right: 10px;
}

.verification-code button {
  padding: 10px;
  background-color: #ffa500;
  color: white;
  border: none;
  border-radius: 3px;
  cursor: pointer;
}

.verification-code button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

.agreement {
  margin-bottom: 15px;
  font-size: 14px;
}

.terms-link {
  color: #ffa500;
  text-decoration: none;
}

.register-btn {
  width: 100%;
  padding: 10px;
  background-color: #ffa500;
  color: white;
  border: none;
  border-radius: 3px;
  cursor: pointer;
  font-size: 16px;
}

.register-btn:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

.login-link {
  text-align: center;
  margin-top: 20px;
  font-size: 14px;
}

.login-link a {
  color: #ffa500;
  text-decoration: none;
}
</style>