<template>
  <div class="user-center-layout">
    <AuthorSidebar />
    <div class="main-content">
      <div class="header-info">
        <h4>添加章节</h4>
        <p>请填写以下信息来添加新的章节。</p>
      </div>
      <div class="actions">
        <ChapterForm 
          :caricatureId="caricatureId" 
          @submit-success="handleSubmitSuccess" 
        />
      </div>
    </div>
  </div>
</template>

<script>
import AuthorSidebar from '@/components/AuthorSidebar.vue';
import ChapterForm from '@/components/ChapterForm.vue';

export default {
  name: 'ChapterAdd',
  components: {
    AuthorSidebar,
    ChapterForm
  },
  data() {
    return {
      caricatureId: this.$route.params.caricatureId
    };
  },
  methods: {
    handleSubmitSuccess() {
      this.$notify.success('章节添加成功');
      this.$router.push(`/author/caricature/${this.caricatureId}/chapters`);
    }
  }
};
</script>

<style scoped>
@import '@/assets/styles/usercenter.css';

h4 {
  margin-bottom: 20px;
  color: #333;
}

.header-info {
  margin-bottom: 20px;
  color: #666;
  font-size: 12px;
  background-color: #fff;
  padding: 10px 20px;
}

.actions {
  background-color: #fff;
  padding: 20px;
}
</style>