<template>
  <form @submit.prevent="submitForm" class="myform">
    <div class="form-group">
      <label for="categoryId"><i>*</i>分类：</label>
      <select id="category" v-model="formData.categoryId" required>
        <option value="">请选择分类</option>
        <option v-for="category in categoryList" :key="category.id" :value="category.id">
          {{ category.name }}
        </option>
      </select>
    </div>
    <div class="form-group">
      <label for="name"><i>*</i>标题：</label>
      <input type="text" id="name" v-model="formData.name" required>
    </div>
    <div class="form-group">
      <label for="cover"><i>*</i>封面：</label>
      <input type="file" id="cover" @change="handleFileUpload" accept="image/*" :required="!isEdit">
    </div>
    <div class="form-group">
      <label for="descr"><i>*</i>简介：</label>
      <textarea id="descr" v-model="formData.descr" required></textarea>
    </div>
    <button type="submit" class="submit-button">{{ submitButtonText }}</button>
  </form>
</template>

<script>
import apiService from '@/api/api';

export default {
  name: 'CaricatureForm',
  props: {
    isEdit: {
      type: Boolean,
      default: false
    },
    initialData: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      formData: {
        categoryId: '',
        name: '',
        cover: null,
        descr: ''
      },
      categoryList: []
    };
  },
  computed: {
    submitButtonText() {
      return this.isEdit ? '更新' : '提交';
    }
  },
  created() {
    this.getCategory();
    if (this.isEdit) {
      this.formData = { ...this.initialData };
    }
  },
  methods: {
    getCategory() {
      apiService.getCategory().then(res => {
        if(res.data.code === 0) {
          this.categoryList = res.data.data[0].category;
        }
      });
    },
    handleFileUpload(event) {
      //先调用上传文件接口  
      console.log(event.target.files[0])
      const formData = new FormData();  
      formData.append('file', event.target.files[0]);
      apiService.uploadFile(formData).then(res => {
        if(res.data.code === 0) {
          this.formData.cover = res.data.data;
        }
      });
    },
    submitForm() {
      const formData = new FormData();
      formData.append('categoryId', this.formData.categoryId);
      formData.append('name', this.formData.name);
      if (this.formData.cover) {
        formData.append('cover', this.formData.cover);
      }
      formData.append('descr', this.formData.descr);

      const apiMethod = this.isEdit ? apiService.updateCaricature : apiService.addCaricature;
      const successMessage = this.isEdit ? '漫画更新成功' : '漫画添加成功';
      const errorMessage = this.isEdit ? '漫画更新失败，请重试' : '漫画添加失败，请重试';

      apiMethod(formData).then(res => {
        if (res.data.code === 0) {
          this.$notify.success(successMessage);
          this.$emit('submit-success');
        } else {
          this.$notify.error(errorMessage);
        }
      }).catch(error => {
        console.error('操作失败:', error);
        this.$notify.error(errorMessage);
      });
    }
  }
};
</script>

<style scoped>
.myform {
  width: 100%;
  max-width: 600px;
  margin: 0 auto;
  font-size: 12px;
}

.form-group {
  margin-bottom: 20px;
}

label {
  display: block;
  margin-bottom: 5px;
  color: #333;
}

label i {
  color: #ff4d4f;
  margin-right: 4px;
}

input[type="text"],
select,
textarea {
  width: 100%;
  padding: 8px 12px;
  border: 1px solid #d9d9d9;
  border-radius: 4px;
  font-size: 14px;
  transition: all 0.3s;
}

input[type="text"]:focus,
select:focus,
textarea:focus {
  border-color:#ffa500;
  box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
  outline: none;
}

input[type="file"] {
  display: block;
  margin-top: 5px;
  font-size: 12px;
}

textarea {
  min-height: 100px;
  resize: vertical;
}

.submit-button {
  background-color: #ffa500;
  color: white;
  border: none;
  padding: 10px 20px;
  font-size: 16px;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.submit-button:hover {
  background-color: #ffa500;
}

.submit-button:active {
  background-color: #ffa500;
}
</style>