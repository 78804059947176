import Vue from 'vue'
import { Tabs, TabPane ,Radio,RadioGroup,Button,Input,Notification,Dialog} from 'element-ui'
import { Select,Option,Table,TableColumn,Pagination} from 'element-ui'
import {Card,Checkbox,MessageBox,Form,FormItem} from 'element-ui'
import VueCropper from 'vue-cropper'

import App from './App.vue'
import router from './router'
import store from './store'
import 'element-ui/lib/theme-chalk/index.css';

Vue.config.productionTip = false

// 全局混入
Vue.mixin({
  methods: {
    authorPageClick(routeName) {
      const isAuthor = this.$store.getters.isAuthor
      const isAuthorCertified = this.$store.getters.isAuthorCertified

      if (isAuthor && !isAuthorCertified && routeName !== 'AuthorCertification') {
        this.$router.push({ name: 'AuthorCertification' })
      } else {
        this.$router.push({ name: routeName })
      }
    }
  }
})
Vue.use(Tabs)
Vue.use(TabPane)
Vue.use(Radio)
Vue.use(RadioGroup)
Vue.use(Button)
Vue.use(Input)
Vue.use(Select)
Vue.use(Option)
Vue.use(Table)
Vue.use(TableColumn)
Vue.use(Pagination)
Vue.use(Card)
Vue.use(Dialog)
Vue.use(VueCropper)
Vue.use(Checkbox)
Vue.use(Form)
Vue.use(FormItem)
Vue.prototype.$notify = Notification
Vue.prototype.$confirm = MessageBox.confirm
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
