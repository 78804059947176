<template>
    <div>
      <el-table :data="orders" style="width: 100%">
        <el-table-column prop="code" label="订单编号" ></el-table-column>
        <el-table-column prop="staticJson" label="订单详情" >
          <template v-slot="scope">
            {{ parseOrderDetail(scope.row.staticJson) }}
          </template>
        </el-table-column>
        <el-table-column prop="channel" label="渠道" width="100"></el-table-column>
        <el-table-column prop="amount" label="金额" width="100"></el-table-column>
        <el-table-column prop="payed" label="支付状态" width="100">
          <template v-slot="scope">
            <span :class="{ 'status-completed': scope.row.payed === true, 'status-pending': scope.row.payed === false }">
              {{ scope.row.payed ? '已支付' : '未支付' }}
            </span>
          </template>
        </el-table-column>
        <el-table-column prop="createTime" label="创建时间" width="180"></el-table-column>
      </el-table>
      <el-pagination
        @current-change="handleCurrentChange"
        :current-page="currentPage"
        :page-size="pageSize"
        :total="total"
        layout="prev, pager, next"
        class="pagination"
      >
      </el-pagination>
    </div>
  </template>
  
  <script>
  export default {
    name: 'OrderTable',
    props: {
      orders: {
        type: Array,
        required: true
      },
      total: {
        type: Number,
        required: true
      },
      currentPage: {
        type: Number,
        required: true
      },
      pageSize: {
        type: Number,
        required: true
      }
    },
    methods: {
      handleCurrentChange(newPage) {
        this.$emit('page-change', newPage);
      },
      parseOrderDetail(staticJson) {
        try {
          const parsedJson = JSON.parse(staticJson);
          return parsedJson.name;
        } catch (error) {
          return staticJson;
        }
      }
    }
  };
  </script>
  
  <style scoped>
  .pagination {
    margin-top: 20px;
    text-align: center;
    border: none;
  }
  
  .status-completed {
    color: #67c23a;
  }
  
  .status-pending {
    color: #e6a23c;
  }
  </style>