<template>
  <form @submit.prevent="submitForm" class="myform">
    <input type="hidden" v-model="formData.caricatureId">
    <input type="hidden" v-model="formData.id">
    
    <div class="form-group">
      <label for="name"><i>*</i>章节名称,示例(第六)：</label>
      <input type="text" id="name" v-model="formData.name" required>
    </div>
    
    <div class="form-group">
      <label for="file"><i>*</i>章节文件：</label>
      <input type="file" id="file" @change="handleFileUpload" accept=".zip,.rar,.7z" required>
    </div>
    
    <div class="form-group">
      <label for="sort"><i>*</i>章节排序：</label>
      <input type="number" id="sort" v-model="formData.sort" required>
    </div>
    
    <div class="form-group">
      <label><i>*</i>付费状态：</label>
      <div>
        <input type="radio" id="free" v-model="formData.isVip" :value="0">
        <label for="free">免费</label>
        <input type="radio" id="paid" v-model="formData.isVip" :value="1">
        <label for="paid">付费</label>
      </div>
    </div>
    
    <button type="submit" class="submit-button">{{ submitButtonText }}</button>
  </form>
</template>

<script>
import apiService from '@/api/api';

export default {
  name: 'ChapterForm',
  props: {
    isEdit: {
      type: Boolean,
      default: false
    },
    initialData: {
      type: Object,
      default: () => ({})
    },
    caricatureId: {
      type: [Number, String],
      required: true
    }
  },
  data() {
    return {
      formData: {
        caricatureId: this.caricatureId,
        id: '',
        name: '',
        file: null,
        sort: 0,
        isVip: 0
      }
    };
  },
  computed: {
    submitButtonText() {
      return this.isEdit ? '更新' : '提交';
    }
  },
  created() {
    if (this.isEdit) {
      this.formData = { ...this.initialData };
    }
  },
  methods: {
    handleFileUpload(event) {
      this.formData.file = event.target.files[0];
    },
    submitForm() {
      const formData = new FormData();
      for (const key in this.formData) {
        if (key !== 'file' || this.formData[key] !== null) {
          formData.append(key, this.formData[key]);
        }
      }

      const apiMethod = this.isEdit ? apiService.updateChapter : apiService.addChapter;
      const successMessage = this.isEdit ? '章节更新成功' : '章节添加成功';
      const errorMessage = this.isEdit ? '章节更新失败，请重试' : '章节添加失败，请重试';

      apiMethod(formData).then(res => {
        if (res.data.code === 0) {
          this.$notify.success(successMessage);
          this.$emit('submit-success');
        } else {
          this.$notify.error(errorMessage);
        }
      }).catch(error => {
        console.error('操作失败:', error);
        this.$notify.error(errorMessage);
      });
    }
  }
};
</script>

<style scoped>
/* 保留之前的样式 */
</style>