<template>
  <div class="user-center-layout">
    <UserSidebar />
    <div class="main-content">
      <div class="header-info">
        <h4>修改密码</h4>
      </div>
      <div class="actions">
        <form @submit.prevent="submitForm" class="myform">
        <div class="form-group">
          <label for="password"><i>*</i>旧密码:</label>
          <input type="password" id="password" v-model="passwordInfo.password" required>
        </div>
        <div class="form-group">
          <label for="newPassword"><i>*</i>设置新密码:</label>
          <input type="password" id="newPassword" v-model="passwordInfo.newPassword" required>
        </div>
        <div class="form-group">
          <label for="confirmPwd"><i>*</i>确认密码:</label>
          <input type="password" id="confirmPwd" v-model="passwordInfo.confirmPwd" required>
        </div>
        <button type="submit" class="submit-button">确认</button>
      </form>
      </div>
    </div>
  </div>
</template>

<script>
import UserSidebar from '@/components/UserSidebar.vue';
import apiService from '@/api/api';

export default {
  name: 'UpdatePassword',
  components: {
    UserSidebar
  },
  data() {
    return {
      passwordInfo: {
        password: '',
        newPassword: '',
        confirmPwd: ''
      }
    };
  },
  methods: {
    submitForm() {
      if (this.passwordInfo.newPassword !== this.passwordInfo.confirmPwd) {
        alert('新密码和确认密码不一致，请重新输入');
        return;
      }

      apiService.changePassword(this.passwordInfo).then(res => {
        if (res.data.code==0) {
          alert('密码修改成功');
          this.resetForm();
        } else {
          alert(res.data.msg);
        }
      }).catch(error => {
        console.error('修改密码时出错:', error);
        alert('修改密码时出错，请重试');
      });
    },
    resetForm() {
      this.passwordInfo = {
        password: '',
        newPassword: '',
        confirmPwd: ''
      };
    }
  }
};
</script>

<style scoped>
@import '@/assets/styles/usercenter.css';

.form-group {
  margin-bottom: 20px;
}
.header-info{
  margin-bottom: 20px;
  color: #666;
  font-size: 12px;
  background-color: #fff;
  padding-left: 20px;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-right: 20px;
}
.myform{
  width: 100%;
  padding:20px
} 
label {
  display: block;
  margin-bottom: 5px;
  color: #666;
  font-size: 12px;
}
label i {
  color: red;
}
input[type="password"] {
  width: 100%;
  padding: 8px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 12px;
}

.submit-button {
  background-color: #ffa500;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 4px;
  cursor: pointer;
  font-size: 12px;
}

.submit-button:hover {
  background-color: #e65c00;
}
</style>