<template>
    <div class="user-center-layout">
      <AuthorSidebar />
      <div class="main-content">
        <div class="header">
          <h4 class="header-title">收入明细</h4>
          <div class="filter-section">
            <span>选择时间</span>
            <el-select v-model="timeFilter" placeholder="全部" @change="handleFilterChange">
              <el-option label="全部" value="all"></el-option>
              <el-option label="最近一周" value="week"></el-option>
              <el-option label="最近一月" value="month"></el-option>
              <el-option label="最近三月" value="threeMonths"></el-option>
            </el-select>
          </div>
        </div>
  
        <div class="actions author-income-statement">
          <el-table :data="incomeList" style="width: 100%">
            <el-table-column prop="price" label="金额" width="180"></el-table-column>
            <el-table-column label="收入类型" width="180">
              <template slot-scope="scope">
                {{ scope.row.priceType === 0 ? '金币' : 'RMB' }}
              </template>
            </el-table-column>
            <el-table-column prop="createTime" label="时间"></el-table-column>
          </el-table>
  
          <el-pagination
            @current-change="handlePageChange"
            :current-page="currentPage"
            :page-size="pageSize"
            :total="total"
            layout="prev, pager, next"
            class="pagination"
          ></el-pagination>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import AuthorSidebar from '@/components/AuthorSidebar.vue';
  import { apiService } from '@/api/api';
  
  export default {
    name: 'IncomeStatement',
    components: {
      AuthorSidebar
    },
    data() {
      return {
        timeFilter: 'all',
        incomeList: [],
        total: 0,
        currentPage: 1,
        pageSize: 10,
        type: 0,
      };
    },
    mounted() {
      this.fetchIncomeData();
    },
    methods: {
      async fetchIncomeData() {
        try {
          const response = await apiService.getStatement({
            pageNo: this.currentPage,
            pageSize: this.pageSize,
            timeFilter: this.timeFilter,
            type: this.type
          });
          if (response.data.code === 0) {
            this.incomeList = response.data.data.list;
            this.total = response.data.data.total;
          } else {
            console.error('获取收入明细失败:', response.data.msg);
          }
        } catch (error) {
          console.error('获取收入明细出错:', error);
        }
      },
      handlePageChange(newPage) {
        this.currentPage = newPage;
        this.fetchIncomeData();
      },
      handleFilterChange() {
        this.currentPage = 1;
        this.fetchIncomeData();
      }
    }
  };
  </script>
  
  <style scoped>
  @import '@/assets/styles/usercenter.css';
  
  .header-title {
    font-size: 18px;
    color: #333;
    margin-bottom: 20px;
  }
  
  .filter-section {
    display: flex;
    align-items: center;
    font-size: 14px;
  }
  
  .filter-section span {
    margin-right: 10px;
  }
  .author-income-statement {
    flex-direction: column;

  }
  
  .pagination {
    margin-top: 20px;
    text-align: center;
  }
  </style>