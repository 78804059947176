<template>
    <div class="user-center-layout">
      <UserSidebar />
      <div class="main-content">
        <div class="header">
          <h4 class="header-title">订单查询</h4>
          <div class="filter-section">
          <span>选择时间</span>
          <el-select v-model="timeFilter" placeholder="全部" @change="handleFilterChange">
            <el-option label="全部" value="all"></el-option>
            <el-option label="最近一周" value="week"></el-option>
            <el-option label="最近一月" value="month"></el-option>
            <el-option label="最近三月" value="threeMonths"></el-option>
          </el-select>
        </div>
        </div>

        <div class="actions">
          <el-tabs v-model="activeTab" class="order-tabs" @tab-click="handleTabClick">
          <el-tab-pane label="全部订单" name="all">
            <OrderTable 
              :orders="orders" 
              :total="total"
              :current-page="currentPage"
              :page-size="pageSize"
              @page-change="handlePageChange"
            />
          </el-tab-pane>
          <el-tab-pane label="已完成订单" name="completed">
            <OrderTable 
              :orders="orders" 
              :total="total"
              :current-page="currentPage"
              :page-size="pageSize"
              @page-change="handlePageChange"
            />
          </el-tab-pane>
          <el-tab-pane label="未完成订单" name="pending">
            <OrderTable 
              :orders="orders" 
              :total="total"
              :current-page="currentPage"
              :page-size="pageSize"
              @page-change="handlePageChange"
            />
          </el-tab-pane>
        </el-tabs>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import UserSidebar from '@/components/UserSidebar.vue';
  import OrderTable from '@/components/OrderTable.vue';
  import { apiService } from '@/api/api';
  
  export default {
    name: 'UserOrder',
    components: {
      UserSidebar,
      OrderTable
    },
    data() {
      return {
        activeTab: 'all',
        timeFilter: 'all',
        orders: [],
        total: 0,
        currentPage: 1,
        pageSize: 10,
      };
    },
    mounted() {
      this.fetchOrders();
    },
    methods: {
      async fetchOrders() {
        try {
          const response = await apiService.getOrderList({
            pageNo: this.currentPage,
            pageSize: this.pageSize,
            timeFilter: this.timeFilter,
            orderType: this.activeTab
          });
          if (response.data.code === 0) {
            this.orders = response.data.data.list;
            this.total = response.data.data.total;
          } else {
            console.error('获取订单列表失败:', response.data.msg);
          }
        } catch (error) {
          console.error('获取订单列表出错:', error);
        }
      },
      handlePageChange(newPage) {
        this.currentPage = newPage;
        this.fetchOrders();
      },
      handleTabClick() {
        this.currentPage = 1;
        this.fetchOrders();
      },
      handleFilterChange() {
        this.currentPage = 1;
        this.fetchOrders();
      }
    }
  };
  </script>
  
  <style scoped>
  @import '@/assets/styles/usercenter.css';

  .page-title {
    font-size: 18px;
    color: #333;
    margin-bottom: 20px;
  }
  
  .filter-section {
    display: flex;
    align-items: center;
    font-size: 14px;
  }
  
  .filter-section span {
    margin-right: 10px;
  }
  
  .order-tabs {
    margin-top: 20px;
    width: 100%;
  }
  
  .el-tabs__item {
    font-size: 14px;
  }
  
  .el-tabs__item.is-active {
    color: #ff6600 !important;
  }
  
  .el-tabs__active-bar {
    background-color: #ff6600 !important;
  }
  </style>