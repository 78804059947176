import { render, staticRenderFns } from "./ChapterForm.vue?vue&type=template&id=6bca8c46&scoped=true"
import script from "./ChapterForm.vue?vue&type=script&lang=js"
export * from "./ChapterForm.vue?vue&type=script&lang=js"
import style0 from "./ChapterForm.vue?vue&type=style&index=0&id=6bca8c46&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6bca8c46",
  null
  
)

export default component.exports