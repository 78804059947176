<template>
  <div class="home">
    <main>
      <section class="category-section">
        <div class="category-list">
          <i class="iconfont icon-category"></i>
          <ul>
            <li v-for="category in categories" :key="category.id">
              <router-link
                :to="{ name: 'Category', params: { categoryId: category.id } }"
                class="category-item"
              >
                {{ category.name }}
              </router-link>
            </li>
          </ul>
        </div>
        <router-link to="/category" class="view-all">
          查看所有分类
        </router-link>
      </section>
      <!-- <div class="banner">
      </div> -->

      <section class="manga-section">
        <div class="manga-section-header">
          <h3>独家作品</h3>
          <router-link :to="{ name: 'Category', params: { privateId: 1 } }" class="more-link">更多</router-link>
        </div>
        <div class="manga-grid">
          <div class="manga-item" v-for="manga in privateList" :key="manga.id">
            <router-link :to="{ name: 'DetailPage', params: { id: manga.id } }">
              <img :src="manga.cover" :alt="manga.title">
              <h4>{{ manga.name }}</h4>
              <p>{{ truncateDescription(manga.descr) }}</p>
              <span class="rating" v-if="manga.dyNews">更新至：{{ manga.dyNews }}</span>
            </router-link>
          </div>
        </div>
      </section>

      <section class="manga-section">
        <div class="manga-section-header">
          <h3>签约作品</h3>
          <router-link :to="{ name: 'Category', params: { signId: 1 } }" class="more-link">更多</router-link>
        </div>
        <div class="manga-grid">
          <div class="manga-item" v-for="manga in signList" :key="manga.id">
            <router-link :to="{ name: 'DetailPage', params: { id: manga.id } }">
              <img :src="manga.cover" :alt="manga.title">
              <h4>{{ manga.name }}</h4>
              <p>{{ truncateDescription(manga.descr) }}</p>
              <span class="rating" v-if="manga.dyNews">更新至：{{ manga.dyNews }}</span>
            </router-link>
          </div>
        </div>
      </section>

      <section class="manga-section">
        <div class="manga-section-header">
          <h3>资费作品</h3>
          <router-link :to="{ name: 'Category', params: { isVipId: 1 } }" class="more-link">更多</router-link>
        </div>
        <div class="manga-grid">
          <div class="manga-item" v-for="manga in vipList" :key="manga.id">
            <router-link :to="{ name: 'DetailPage', params: { id: manga.id } }">
              <img :src="manga.cover" :alt="manga.title">
              <h4>{{ manga.name }}</h4>
              <p>{{ truncateDescription(manga.descr) }}</p>
              <span class="rating" v-if="manga.dyNews">更新至：{{ manga.dyNews }}</span>
            </router-link>
          </div>
        </div>
      </section>
      <section class="manga-section">
        <div class="manga-section-header">
          <h3>最近更新</h3>
          <router-link :to="{ name: 'Category', params: { premiumId: 1 } }" class="more-link">更多</router-link>
        </div>
        <div class="manga-grid">
          <div class="manga-item" v-for="manga in premiumList" :key="manga.id">
            <router-link :to="{ name: 'DetailPage', params: { id: manga.id } }">
              <img :src="manga.cover" :alt="manga.title">
              <h4>{{ manga.name }}</h4>
              <p>{{ truncateDescription(manga.descr) }}</p>
              <span class="rating" v-if="manga.dyNews">更新至：{{ manga.dyNews }}</span>
            </router-link>
          </div>
        </div>
      </section>
    </main>
  </div>
</template>

<script>
import apiService from '@/api/api';
export default {
  name: 'Home',
  data() {
    return {
      premiumList: [],
      privateList: [],
      signList: [],
      vipList: [],
      categories: []
    }
  },
  methods: {
    fetchCategories() {
        apiService.getCategory().then(response => {
          if(response.data.code === 0){
            this.categories = response.data.data[0].category;
          }
        });

    },
    fetchHomeData(){
      apiService.getHomeData().then(response => {
        if(response.data.code === 0){
          let r = response.data.data; 
          this.premiumList = r.premium; //热门
          this.privateList = r.private; //独家
          this.signList = r.sign;  //签约
          this.vipList = r.vip; //资费
        }
      });
    },
    truncateDescription(descr) {
      //增加一个判断，如果descr是null，则返回空字符串
      if (descr == null) {
        return '';
      }
      if (descr.length > 10) {
        return descr.substring(0, 10) + '...';
      }
      return descr;
    }
  },
  mounted() {
    this.fetchCategories();
    this.fetchHomeData();
  }
}
</script>

<style scoped>
.home {
  font-family: Arial, sans-serif;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px;
}

.header-top {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 0;
}

.logo img {
  height: 40px;
}

.search-bar {
  display: flex;
}

.search-bar input {
  padding: 5px 10px;
  width: 300px;
}

.search-bar button {
  padding: 5px 15px;
  background-color: #ffa500;
  color: white;
  border: none;
  cursor: pointer;
}

.user-info {
  display: flex;
  align-items: center;
}

.user-avatar {
  width: 30px;
  height: 30px;
  background-color: #ddd;
  border-radius: 50%;
  margin-right: 10px;
}

.login-btn {
  background-color: #ffa500;
  color: white;
  padding: 5px 10px;
  text-decoration: none;
  border-radius: 3px;
}

nav ul {
  display: flex;
  list-style-type: none;
  padding: 0;
  background-color: #f0f0f0;
}

nav ul li {
  padding: 10px 20px;
}

nav ul li a {
  text-decoration: none;
  color: #333;
}

nav ul li a.active {
  color: #ffa500;
}

.banner {
  height: 300px;
  background-color: #ddd;
  margin: 20px 0;
}

.category-tags {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 20px;
}

.manga-section-header{
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 12px;
  margin-top: 20px;
  border-bottom: 1px solid #f0f0f0;
  margin-bottom: 10px;
}

.tag {
  background-color: #f0f0f0;
  padding: 5px 10px;
  margin-right: 10px;
  margin-bottom: 10px;
  text-decoration: none;
  color: #333;
  border-radius: 3px;
}
.category-section{
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 12px;
  margin-top: 20px;
  border-bottom: 1px solid #f0f0f0;
}
.category-list{
  display: flex;
  align-items: center;
  font-size: 12px;
}

.category-list ul{
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  padding: 0;
  margin: 0;
}
.category-list ul li{
  margin-right: 10px;
  margin-bottom: 10px;
  list-style: none;
}
.category-list ul li a{
  text-decoration: none;
  color: #333;
  list-style: none;
}

.manga-section {
  margin-bottom: 40px;
}

.manga-section h2 {
  border-left: 4px solid #ffa500;
  padding-left: 10px;
}

.manga-grid {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  gap: 10px;
}

.manga-item {
  text-align: center;
  font-size: 12px;
}

.manga-item a{
  text-decoration: none;
  color: #fff;
}

.manga-item img {
  width: 100%;
  height: auto;
}
.manga-item h4{
  font-size: 14px;
  color: #333;
  margin-top: 10px;
  margin-bottom: 0px;
  padding: 0;
}
.manga-item span{
  font-size: 10px;
}

.rating {
  background-color: #ffa500;
  padding: 2px 5px;
  border-radius: 3px;
}

.manga-list {
  display: grid;
  grid-template-columns:  repeat(6, 1fr);
  gap: 10px;
}

.manga-list .manga-item {
  display: flex;
  text-align: left;
}

.manga-list .manga-item img {
  width: 80px;
  height: 120px;
  margin-right: 10px;
}

.more-link {
  display: block;
  text-align: right;
  color: #666;
  text-decoration: none;
}

footer {
  margin-top: 40px;
  text-align: center;
  color: #666;
  font-size: 14px;
}

.footer-links {
  margin-bottom: 10px;
}

.footer-links a {
  margin: 0 10px;
  color: #666;
  text-decoration: none;
}
</style>