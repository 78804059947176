<template>
  <div class="sidebar">
    <div class="sidebar-group">
      <h3>漫画专区</h3>
      <ul>
        <router-link to="/favorite-manager" class="custom-link"><li :class="{ active: $route.path === '/favorite-manager' }">我的收藏</li></router-link>
        <router-link to="/read-history-manager" class="custom-link"><li :class="{ active: $route.path === '/read-history-manager' }">阅读历史</li></router-link>
        <router-link to="/user-order-list" class="custom-link"><li :class="{ active: $route.path === '/user-order-list' }">我的购买</li></router-link>
        <router-link to="/comment-manager" class="custom-link"><li :class="{ active: $route.path === '/comment-manager' }">我的评论</li></router-link>
      </ul>
    </div>
    <div class="sidebar-group">
      <h3>财务记录</h3>
      <ul>
        <router-link to="/user/orders" class="custom-link"><li :class="{ active: $route.path === '/user/orders' }">充值记录</li></router-link>
        <router-link to="/consume-record" class="custom-link"><li :class="{ active: $route.path === '/consume-record' }">消费记录</li></router-link>
      </ul>
    </div>
    <div class="sidebar-group">
      <h3>账户设置</h3>
      <ul>
        <router-link to="/update-user-info" class="custom-link"><li :class="{ active: $route.path === '/update-user-info' }">修改资料</li></router-link>
        <router-link to="/update-password" class="custom-link"><li :class="{ active: $route.path === '/update-password' }">修改密码</li></router-link>
        <!-- <li>账户绑定</li>
        <li>消息列表</li> -->
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: 'UserSidebar'
}
</script>

<style scoped>
.custom-link{
  text-decoration: none;
}
.sidebar {
  width: 200px;
  background-color: #fff;
  text-align: center;
  margin: 20px;
}

.sidebar-group {
  margin-bottom: 20px;
  border-bottom: 1px solid #e5e5e5;
}

.sidebar-group h3 {
  font-size: 16px;
  color: #999;
  margin-bottom: 10px;
  border-bottom: 1px solid #e5e5e5;
  padding-bottom: 10px;
}

.sidebar-group ul {
  list-style-type: none;
  padding: 0;
  font-size: 12px;
}

.sidebar-group li {
  color: #666;
  padding: 10px 0;
  cursor: pointer;
}

.sidebar-group li:hover,
.sidebar-group li.active {
  background-color: #ffa500;
  color: #fff;
  font-weight: bold;
  border:none;
}

.sidebar-group li:hover a,
.sidebar-group li.active a {
  color: #fff;
}
</style>