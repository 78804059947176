<template>
  <div class="user-center-layout">
    <AuthorSidebar />
    <div class="main-content">
      <div class="header-info">
        <h4>添加漫画</h4>
        <p>请填写以下信息来添加新的漫画作品。</p>
      </div>
      <div class="actions">
        <CaricatureForm @submit-success="handleSubmitSuccess" />
      </div>
    </div>
  </div>
</template>

<script>
import AuthorSidebar from '@/components/AuthorSidebar.vue';
import CaricatureForm from '@/components/CaricatureForm.vue';

export default {
  name: 'CaricatureAdd',
  components: {
    AuthorSidebar,
    CaricatureForm
  },
  methods: {
    handleSubmitSuccess() {
      // 处理提交成功后的操作，比如跳转页面
      this.$notify.success('漫画添加成功');
      this.$router.push('/author/caricature-list');
    }
  }
};
</script>

<style scoped>
@import '@/assets/styles/usercenter.css';

h4 {
  margin-bottom: 20px;
  color: #333;
}

.header-info {
  margin-bottom: 20px;
  color: #666;
  font-size: 12px;
  background-color: #fff;
  padding: 10px 20px;
}

.actions {
  background-color: #fff;
  padding: 20px;
}
</style>