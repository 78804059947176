<template>
  <div class="rank-list">
    <el-card v-for="(item, index) in rankItems" :key="index" class="rank-item">
      <div class="rank-item-content"  @click="goToDetail(item.id)">
        <span class="rank-number">{{ index + 1 }}</span>
        <img :src="item.cover" :alt="item.title" class="rank-cover">
        <div class="rank-info">
          <h3>{{ item.name }}</h3>
          <p>作者: {{ item.authorName?item.authorName:'-' }}</p>
          <p>{{ truncateText(item.descr, 15) }}</p>
          <p>更新至：{{ item.dyNews?item.dyNews.name:'-' }} </p>
          <p>{{countTitle}}{{ item.num }}</p>
        </div>
      </div>
    </el-card>
  </div>
</template>

<script>
import apiService from '@/api/api';
export default {
  name: 'RankList',
  props: {
    qtype: {
      type: Number,
      required: true
    },
    rankTypeList: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      rankItems: [],
      rankType: 0,
      countTitle: '评分'
    };
  },
  watch: {
    qtype: {
      immediate: true,
      handler: 'fetchRankData'
    }
  },
  mounted() {
    this.fetchRankData();
    this.rankTypeList.forEach(item => {
      if (item.type === this.qtype) {
        this.countTitle = item.title;
      }
    });
  },
  methods: {
    goToDetail(id) {
      console.log(id);
      this.$router.push(`/detail/${id}`);
    },
    async fetchRankData() {
      const params = {
        type: this.qtype,
      };
      const response = await apiService.getRankList(params);
      if (response.data.code === 0) {
        this.rankItems = response.data.data[this.qtype];
      }
    },
    handleCurrentChange(val) {
      this.currentPage = val;
      this.fetchRankData();
    },
    truncateText(text, maxLength) {
      // 如果text为空，返回'--'
      if (!text) {
        return '暂无简介';
      }
      if (text.length > maxLength) {
        return text.substring(0, maxLength) + '...';
      }
      return text;
    }
  }
};
</script>

<style scoped>
.rank-list {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.rank-item {
  width: calc(32% - 10px);
  margin-bottom: 10px;
}

@media (max-width: 768px) {
  .rank-item {
    width: calc(50% - 10px);
  }
}

@media (max-width: 480px) {
  .rank-item {
    width: 100%;
  }
}

.rank-item {
  margin-bottom: 20px;
  margin-right: 20px;
}

.rank-item-content {
  display: flex;
  align-items: start;
}

.rank-number {
  margin-right: 10px;
    background-color: #ffa500;
    color: white;
    border: none;
    padding: 1px 5px;
    border-radius: 15px;
    cursor: pointer;
}

.rank-cover {
  width: 100px;
  height: 140px;
  object-fit: cover;
  margin-right: 20px;
}

.rank-info {
  font-size: 12px;
}

.rank-score {
  font-size: 12px;
  color: #999;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.pagination {
  align-self: center;
  margin-top: 20px;
}
</style>