import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Login from '../views/Login.vue'
import Register from '../views/Register.vue'
import ResetPassword from '../views/ResetPassword.vue'
import Category from '../views/Category.vue'
import UserDetail from '@/views/UserDetail.vue'
import DetailPage from '@/views/DetailPage.vue'
import FavoriteManager from '@/views/FavoriteManager.vue'
import CommentManager from '@/views/CommentManager.vue'
import UpdateUserInfo from '@/views/UpdateUserInfo.vue'
import UpdatePassword from '@/views/UpdatePassword.vue'
import AuthorDashboard from '@/views/AuthorDashboard.vue'
import AuthorCertification from '@/views/AuthorCertification.vue'
import UserOrder from '@/views/UserOrder.vue'
import ReadHistoryManager from '@/views/ReadHistoryManager.vue'
import ConsumeRecordManager from '@/views/ConsumeRecordManager.vue'
import Rank from '@/views/Rank.vue'
import IncomeStatement from '@/views/IncomeStatement.vue'
import SettlementRecord from '@/views/SettlementRecord.vue' 
import About from '@/views/About.vue'
import UserOrderList from '@/views/UserOrderList.vue'
import ReadChapter from '@/views/ReadChapter.vue'
import CaricatureAdd from '@/views/CaricatureAdd.vue'
import CaricatureEdit from '@/views/CaricatureEdit.vue'
import ChapterAdd from '@/views/ChapterAdd.vue'
import ChapterEdit from '@/views/ChapterEdit.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/login',
    name: 'Login',
    component: Login
  },
  {
    path: '/register',
    name: 'Register',
    component: Register
  },
  {
    path: '/reset-password',
    name: 'ResetPassword',
    component: ResetPassword
  },
  {
    path: '/category/:categoryId?',
    name: 'Category',
    component: Category,
    props: true
  },
  {
    path: '/user',
    name: 'UserDetail',
    component: UserDetail,
  },
  {
    path: '/detail/:id',
    name: 'DetailPage',
    component: DetailPage
  },
  {
    path: '/user-order-list',
    name: 'UserOrderList',
    component: UserOrderList
  },
  {
    path: '/favorite-manager',
    name: 'FavoriteManager',
    component: FavoriteManager
  },
  {
    path: '/read-history-manager',
    name: 'ReadHistoryManager',
    component: ReadHistoryManager
  },
  {
    path: '/comment-manager',
    name: 'CommentManager',
    component: CommentManager
  },
  {
    path: '/update-user-info',
    name: 'UpdateUserInfo',
    component: UpdateUserInfo
  },
  {
    path: '/update-password',
    name: 'UpdatePassword',
    component: UpdatePassword
  },
  {
    path: '/author-dashboard',
    name: 'AuthorDashboard',
    component: AuthorDashboard
  },
  {
    path: '/author-certification',
    name: 'AuthorCertification',
    component: AuthorCertification
  },
  {
    path: '/user/orders',
    name: 'UserOrder',
    component: UserOrder
  },
  {
    path: '/consume-record',
    name: 'ConsumeRecordManager',
    component: ConsumeRecordManager
  },
  {
    path: '/rank',
    name: 'Rank',
    component: Rank
  },
  {
    path: '/income-statement',
    name: 'IncomeStatement',
    component: IncomeStatement
  },
  {
    path: '/settlement-records',
    name: 'SettlementRecord',
    component: SettlementRecord
  },
  {
    path: '/about',
    name: 'About',
    component: About
  },
  {
    path: '/read-chapter/:id/:chapterId',
    name: 'ReadChapter',
    component: ReadChapter
  },
  {
    path: '/author/add-caricature',
    name: 'CaricatureAdd',
    component: CaricatureAdd,
    meta: { requiresAuth: true }
  },
  {
    path: '/author/caricature-add',
    name: 'CaricatureAdd',
    component: CaricatureAdd,
    meta: { requiresAuth: true }
  },
  {
    path: '/author/caricature-edit/:id',
    name: 'CaricatureEdit',
    component: CaricatureEdit,
    meta: { requiresAuth: true }
  },
  {
    path: '/author/caricature/:caricatureId/chapter-add',
    name: 'ChapterAdd',
    component: ChapterAdd,
    meta: { requiresAuth: true }
  },
  {
    path: '/author/caricature/:caricatureId/chapter-edit/:chapterId',
    name: 'ChapterEdit',
    component: ChapterEdit,
    meta: { requiresAuth: true }
  },
  // 添加其他路由...
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})


export default router