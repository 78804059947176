<template>
  <header class="banner">
    <div v-if="!isLoggedIn">
      <div class="banner-content">
      <div class="logo">
        <img src="@/assets/logo.png" alt="Logo">
      </div>
      <div>
        <nav>
      <ul>
        <li><router-link to="/" exact>首页</router-link></li>
        <!-- <li><router-link to="/update">更新</router-link></li> -->
        <li><router-link to="/rank">排行</router-link></li>
        <li><router-link to="/category">分类</router-link></li>
        <!-- <li><router-link to="/bookshelf">书架</router-link></li> -->
      </ul>
    </nav>
      </div>
      <!-- <div class="search-bar">
        <input type="text" placeholder="搜索漫画">
        <button>搜索</button>
      </div> -->
      <div class="user-info">
        <span class="user-avatar"></span>
        <router-link v-if="isLoggedIn" to="/user" class="user-name">{{ userName }}</router-link>
        <router-link v-if="!isLoggedIn" to="/login" class="login-btn">登录</router-link>
      </div>
    </div>
    </div>
    <div v-if="isLoggedIn">
      <div class="banner-content">
      <div class="logo">
        <img src="@/assets/logo.png" alt="Logo">
      </div>
      <div>
        <nav>
      <ul>
        <li><router-link to="/" exact>首页</router-link></li>
        <li><router-link to="/rank">排行</router-link></li>
        <li><router-link to="/category">分类</router-link></li>
        <li><router-link to="/update-user-info">账户设置</router-link></li>
      </ul>
    </nav>
      </div>
      <!-- <div class="search-bar">
        <input type="text" placeholder="搜索漫画">
        <button>搜索</button>
      </div> -->
      <div class="user-info go-author">
        <div class="user-info-item"><p @click="goUserCenter">用户中心</p></div>
        <div class="user-info-item"><p @click="goAuthorCenter">作者中心</p></div>
        <div class="user-info-item"><p @click="handleLogout">退出登录</p></div>
      </div>
    </div>
    </div>
  </header>
</template>

<script>
import { mapState, mapActions,mapMutations} from 'vuex'
import apiService from '@/api/api';
export default {
  name: 'Banner',
  computed: {
    ...mapState(['isLoggedIn', 'user'])
  },
  data() {
    return {
      userName: '游客',
      isAuthor:false,
      userAvatar:''
    }
  },
  methods: {
    ...mapActions(['logout', 'checkLoginStatus']),
    ...mapMutations(['setLoginStatus']),
    goAuthorCenter() {
      this.$router.push('/author-dashboard');
    },
    goUserCenter() {
      this.$router.push('/user');
    },
    handleLogout() {
      this.logout();
      this.$router.push('/');
    },
    async checkLoginStatus() {
      const token = localStorage.getItem('token');
      if (token) {
        try {
          const response = await apiService.getUserInfo();
          if (response.data.code === 0) {
            this.setLoginStatus(true);
            this.userName = response.data.data.account || response.data.data.nickname;
            this.userAvatar = response.data.data.avatar;
          } else {
            this.setLoginStatus(false);
          }
        } catch (error) {
          console.error('获取用户信息失败:', error);
          this.setLoginStatus(false);
        }
      } else {
        this.setLoginStatus(false);
      }
    }
  },
  mounted() {
    this.checkLoginStatus();
  }
}
</script>

<style scoped>
.go-author{
  cursor: pointer;
  color: white;
  padding: 5px 5px;
  text-decoration: none;
  border-radius: 3px;
  font-size: 12px;
}
.user-info-item{
  margin-right: 10px;
  cursor: pointer;
  background-color: #ffa500;
  color: white;
  padding: 0 10px;
  text-decoration: none;
  border-radius: 3px;
  font-size: 12px;
  
}
.banner {
  font-family: Arial, sans-serif;
  background-color: #fff;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}

.banner-content {
  max-width: 1200px;
  margin: 0 auto;
  padding: 10px 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 14px;
}

.logo img {
  height: 40px;
}

.search-bar {
  position: relative;
  display: inline-block;
}

input[type="text"] {
  padding: 8px 70px 8px 15px;
  border: 1px solid #ccc;
  border-radius: 8px; /* 修改这里来调整圆角程度 */
  width: 250px;
  font-size: 12px;
}

button {
  position: absolute;
  right: 5px;
  top: 50%;
  transform: translateY(-50%);
  background: #f0f0f0;
  border: none;
  border-radius: 4px; /* 给按钮也添加一点圆角 */
  padding: 5px 10px;
  cursor: pointer;
  font-size: 14px;
  color: #333;
}

button:hover {
  background: #e0e0e0;
}

.user-info {
  display: flex;
  align-items: center;
}

.user-avatar {
  width: 30px;
  height: 30px;
  background-color: #ddd;
  border-radius: 50%;
  margin-right: 10px;
}

.login-btn, .logout-btn {
  background-color: #ffa500;
  color: white;
  padding: 5px 10px;
  text-decoration: none;
  border-radius: 3px;
}


nav ul {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0;
  list-style-type: none;
  display: flex;
}

nav ul li {
  padding: 10px 20px;
}

nav ul li a {
  text-decoration: none;
  color: #333;
}

nav ul li a.router-link-active {
  color: #ffa500;
}
</style>