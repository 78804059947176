<template>
  <div class="category-page">
    <div class="filter-section">
      <div v-for="(options, category) in filters" :key="category" class="filter-group">
        <span class="filter-title">{{ labeText[category] }}：</span>
        <ul class="filter-options">
          <li v-for="option in options" :key="option.value"
              :class="{ active: selectedFilters[category] === option.value }"
              @click="selectFilter(category, option.value)">
            {{ option.label }}
          </li>
        </ul>
      </div>
    </div>

    <div class="manga-list">
      <div v-for="manga in filteredMangas" :key="manga.id" class="manga-item" @click="goToDetailPage(manga.id)">
        <img :src="manga.cover" :alt="manga.title">
        <div class="manga-info">
          <h4>{{ manga.name }}</h4>
          <p class="author">{{ manga.author }}</p>
          <p class="description">{{ truncateDescription(manga.descr) }}</p>
          <!-- <p class="update">更新至：{{ manga.latestChapter }}</p>
          <p class="popularity">人气：{{ manga.popularity }}</p> -->
        </div>
      </div>
    </div>

    <div class="pagination">
      <!-- 分页组件 -->
      <button @click="changePage(-1)" :disabled="currentPage === 1">上一页</button>
      <span>{{ currentPage }} / {{ totalPages }}</span>
      <button @click="changePage(1)" :disabled="currentPage === totalPages">下一页</button>
    </div>
  </div>
</template>

<script>
import apiService from '@/api/api';

export default {
  props: {
    categoryId: {
      type: Number,
      required: false
    },
    privateId: {
      type: Number,
      required: false
    },
    premiumId: {
      type: Number,
      required: false
    },
    signId: {
      type: Number,
      required: false
    },
    isVipId: {    
      type: Number,
      required: false
    }

  },
  name: 'Category',
  data() {
    return {
      filters: {
        'cateogryId': [],
        'isPrivate': [
          { label: '全部', value: '-1' },
          { label: '否', value: '0' },
          { label: '是', value: '1' },
        ],
        'isPremium': [
          { label: '全部', value: '-1' },
          { label: '热门', value: '0' },
          { label: '精品', value: '1' },
        ],
        'isSign': [
          { label: '全部', value: '-1' },
          { label: '未签约', value: '0' },
          { label: '已签约', value: '1' },
        ],
        'status': [
          { label: '全部', value: '-1' },
          { label: '连载中', value: '0' },
          { label: '已完结', value: '1' }
        ],
        'isVip': [
          { label: '全部', value: '-1' },
          { label: '免费', value: '0' },
          { label: '付费', value: '1' },
          { label: 'VIP', value: '2' }
        ]
      },
      selectedFilters: {
        'cateogryId': '-1',
        'isPrivate': '-1',
        'isPremium': '-1',
        'isSign': '-1',
        'status': '-1',
        'isVip': '-1'
      },
      labeText:{
        'cateogryId': '分类',
        'isPrivate': '独家',
        'isPremium': '品质',
        'isSign': '签约',
        'status': '进度',
        'isVip': '资费'
      },
      mangas: [],
      currentPage: 1,
      totalPages: 1,
      pageSize: 10
    }
  },
  computed: {
    filteredMangas() {
      // 实际项目中，这里应该调用API获取筛选后的漫画列表
      return this.mangas;
    }
  },
  mounted() {
    if(this.categoryId){
      this.selectedFilters.cateogryId = this.categoryId;
    }
    if(this.privateId){
      this.selectedFilters.isPrivate = this.privateId.toString();
    }
    if(this.premiumId){
      this.selectedFilters.isPremium = this.premiumId.toString();
    }
    if(this.signId){
      this.selectedFilters.isSign = this.signId.toString();
    }
    if(this.isVipId){
      this.selectedFilters.isVip = this.isVipId.toString();
    }
    this.getCategoryList();
    this.fetchMangas();
  },
  methods: {
    truncateDescription(description) {
      //增加一个判断，如果description为空，则返回空字符串
      if (description == null || description == '') {
        return '';
      }
      if (description.length > 12) {
        return description.substring(0, 12) + '...';
      }
      return description;
    },
    selectFilter(category, value) {
      this.selectedFilters[category] = value;
      this.currentPage = 1;
      this.fetchMangas();
    },
    changePage(delta) {
      this.currentPage += delta;
      this.fetchMangas();
    },
    async getCategoryList() {
      await apiService.getCategory().then(response => {
        let categoryList = response.data.data[0].category;
        let t = {};
        t[0]={label:'全部',value:'-1'};
        for (let i = 0; i < categoryList.length; i++) {
          t[i+1]={label:categoryList[i].name,value:categoryList[i].id}
        }
        this.filters.cateogryId = t;
      }).catch(error => {
        console.error('获取分类列表失败:', error);
      });
    },
    async fetchMangas() {
      //查询漫画列表
      let selectedFilters = { ...this.selectedFilters };
      Object.keys(selectedFilters).forEach(key => {
        if (selectedFilters[key] === '-1') {
          delete selectedFilters[key];
        }
      });
      let params = {
        pageNo: this.currentPage,
        pageSize: this.pageSize,
        ...selectedFilters
      };
      await apiService.getCaricatureList(params).then(response => {
        if(response.data.code == 0){
          this.mangas = response.data.data.list;
          let totalPages = Math.ceil(response.data.data.total/this.pageSize);
          this.totalPages = totalPages;
      }
      });
    },
    goToDetailPage(id) {
      this.$router.push(`/detail/${id}`);
    }
  },
}
</script>

<style scoped>
.filter-title {
  float: left;
}
.filter-header {
  font-size: 16px;
  float: left;
}
.category-page {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
}

.filter-section {
  border-top: 2px solid #ffa500;
  padding: 10px 0;
  font-size: 12px;
  color: #666;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
}

.filter-group {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  margin-left: 10px;
}

.filter-title {
  /* font-weight: bold; */
  margin-right: 10px;
  font-weight: 400;
}

.filter-options {
  display: flex;
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.filter-options li {
  margin-right: 15px;
  cursor: pointer;
  padding: 2px 10px;
  text-align: center;
}

.filter-options li.active {
  color: #fff;
  background-color: #ffa500;
  border-radius: 10px;
  border: 1px solid #ffa500;
}

.manga-list {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  gap: 20px;
  margin-top: 20px;
  font-size: 12px;
  /* box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1); */
  /* padding: 20px; */
}

.manga-item {
  border-radius: 5px;
  overflow: hidden;
}

.manga-item img {
  width: 100%;
  height: 250px;
  object-fit: cover;
  padding-top: 10px;
}

.manga-item h4{
  margin: 0;
  padding: 0;
}
.manga-info {
  padding-left: 10px;
  padding-right: 10px;
  margin-top: 10px;
  text-align: center;
}
.manga-info img{
  padding-top: 10px;
}
.manga-info p {
  margin: 5px 0;
  font-size: 10px;
  color: #666;
}

.pagination {
  margin-top: 20px;
  text-align: center;
}

.pagination button {
  margin: 0 10px;
  padding: 5px 10px;
  background-color: #ffa500;
  color: white;
  border: none;
  border-radius: 3px;
  cursor: pointer;
}

.pagination button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}
</style>